.prev-btn, .next-btn {
  font-size: 2rem;
  margin-right: 0.7rem;
  cursor: pointer;
}

.site-page-header {
  border: 1px solid rgb(235, 237, 240);
}

.site-page-header {
  border-radius: 0;
}