.ant-descriptions-title {
  color: $gray-dark;
  font-weight: $font-weight-semibold;
  font-size: 16px;
  line-height: $line-height-base;
}

.ant-descriptions-item-label {
  color: $gray-dark;
  font-size: $font-size-spocto;
}

.ant-descriptions-item-content,
.ant-divider {
  color: $gray-light;
  font-size: $font-size-spocto;
}

.ant-descriptions.bordered {
  .ant-descriptions-item-content,
  .ant-descriptions-item-label {
    border-right: 1px solid $border-color;
  }
}
.ant-descriptions-item-content {
  display: flex;
  justify-content: space-between;
}

.ant-descriptions .ant-form-item
{
  margin-bottom: 1px !important;
}
