$chat-header-height: 3.75rem;
$chat-footer-height: 3.75rem;
$chatmenu-header-height: 3.75rem;

.chat {
  border: 1px solid $border-color;
  border-radius: $border-radius;

  .chat-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 11px;

    .chat-content-header {
      height: $chat-header-height;
      background-color: $gray-lighter;
      border-bottom: 1px solid $border-color;
      padding: 0 0.9375rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .chat-content-body {
      height: calc(
        100vh - #{$chat-header-height} - #{$chat-footer-height} - #{$layout-content-gutter} -
          #{$header-height} - #{$footer-height} - #{1.875rem}
      );
      padding: 0.9375rem;
      overflow-y: auto;
      background-color: #e5ddd5;
      flex-grow: 1;

      .msg {
        display: flex;
        margin-bottom: 0.6rem;

        .bubble {
          max-width: 75%;
          position: relative;
          line-height: 1.3;

          .bubble-wrapper {
            padding: 0.4rem 0.6rem;
            border-radius: $border-radius;
            overflow: hidden;
            border-radius: 0.625rem;
          }
        }

        &.datetime {
          padding: 0.9375rem 0;
          margin-bottom: 0px;
          font-size: 0.68rem;
          font-weight: $font-weight-semibold;
          justify-content: center;
          height: 10px;

          .ant-divider-inner-text {
            font-size: $font-size-sm;
          }
        }

        .file {
          .anticon {
            margin-right: 0.625rem;
            font-size: 1.25rem;
          }
        }

        &.msg-recipient {
          .bubble {
            .bubble-wrapper {
              background: $gray-lighter;
              color: $gray-dark;

              img {
                max-width: 15.625rem;
                cursor: pointer;
              }
            }
          }
        }

        &.botdone {
          display: flex;
          flex-direction: column;
        }

        &.msg-sent {
          justify-content: flex-end;

          .bubble {
            .bubble-wrapper {
              background-color: $primary;
              color: $white;
            }
          }
        }
      }
    }

    .chat-content-footer {
      background-color: $gray-lighter;
      height: $chat-footer-height;
      padding: 0 0.25rem;
      display: flex;
      align-items: center;
      .ant-input-affix-wrapper {
        padding: 0.3rem 0.5rem;
      }
    }
    .chat-content-suggestions {
      padding: 0 1rem 0.5rem;
      background-color: $gray-lighter;
    }
    .ant-tag {
      width: 100%;
      height: auto !important;
      white-space: normal;
      cursor: pointer;
    }

    .ant-tag:before {
      color: $primary;
      content: "Reply: ";
    }
  }

  .chat-content-empty {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 53rem;
      width: 100%;
    }
  }
}

.chat-menu {
  width: 100%;
  background-color: $gray-lighter;
  font-size: 11px;
  .chat-menu-toolbar {
    height: $chatmenu-header-height;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .chat-menu-toolbar {
    border-bottom: 1px solid $border-color;
    padding: 0;
    display: flex;
    align-items: center;

    .ant-input-affix-wrapper {
      padding: 0.3rem 0.5rem;
      border: 0px;
      width: 95%;

      .ant-input-prefix {
        display: flex;
        align-items: center;
        color: $input-color-placeholder;
      }

      &.ant-input-affix-wrapper-focused {
        box-shadow: none;
      }

      .ant-input {
        font-size: $font-size-lg;

        &:focus,
        .ant-input-focused {
          box-shadow: none;
        }
      }
    }
  }

  .chat-menu-list {
    height: calc(
      100vh - #{$chat-header-height} - #{$chat-footer-height} - #{$layout-content-gutter} -
        #{$header-height} - #{1.875rem}
    );
    overflow: auto;
    position: relative;

    .chat-menu-list-item {
      @include transition(all 0.3s ease-in-out);
      padding: 0.815rem 1.25rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      .chat-menu-list-item-time {
        font-size: $font-size-sm;
        color: $text-muted;
        font-weight: $font-weight-semibold;
      }

      &:not(.last) {
        border-bottom: 1px solid $border-color;
      }

      &:hover {
        background-color: $select-item-hover;
      }

      &.selected {
        background-color: $select-item-selected;
      }

      .avatar-status {
        .avatar-status-subtitle {
          width: 10.375rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}

.profile {
  width: 100%;
  background-color: $gray-lighter;
  display: flex;
  flex-direction: column;
  font-size: 11px;

  .profile-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
  }

  .profile-content {
    display: flex;
    flex-direction: column;
  }

  .profile-content-items {
    display: flex;
    margin: 0.5rem;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;

    span  {
      margin-right: 5px;
    }
  }
}

.msg-time {
  float: right;
  margin-left: 10px;
}
.msg-recipient-time {
  color: $gray-dark;
}

.msg-sent-time {
  color: $spocto-secondary;
}
.hangup-btn {
  margin: 5px;
  text-align: center;
}

.flex-item-right {
  margin-left: auto;
  font-size: 16px;
}
